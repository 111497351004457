import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";

import io from "socket.io-client"
import { socket } from './socket';
import { ConnectionState } from './ConnectionState';
import { ConnectionManager } from './ConnectionManager';

import Header from './layout/header';
import Footer from './layout/footer';
import Create from './view/Create';
import Poll from './view/Poll';
import Join from './view/Join';
import Home from './view/Home';

function App() {
  const [data, setData] = useState()
  const [poll, setPoll] = useState()
  const [user, setUser] = useState()
  const [anon, setAnon] = useState()
  const [list, setList] = useState()
  const [exist, setExist] = useState()
  const [isConnected, setIsConnected] = useState(socket.connected)
  const navigate = useNavigate()

  useEffect(() => {
    function onConnect(data) {
      console.log(' 🐞 onConnect', data)
      setIsConnected(true)
    }

    function onDisconnect(data) {
      console.log(' 🐞 onDisconnect', data)
      setIsConnected(false)
    }

    function onCreate(data) {
      console.log(' 🪲 onCreate data:', data)
      setAnon(undefined)
      navigate(`/poll/${data}`)
    }

    function onExist(data) {
      console.log(' 🪲 onExist data: ', data)
      setExist(Boolean(data))
      if (data) {
        console.log(' 🪲 onExist join: ', data)
        socket.emit('join', data);
      }
    }

    function onJoin(data) {
      console.log(' 🪲 onJoin data: ', data)
      setUser(data.user)
      if (data.code === 401) {
        navigate(`/poll/${data.pid}/join`)
      }
      if (data.code === 200) {
        localStorage.setItem(data.pid, data.token)
        navigate(`/poll/${data.pid}`)
      }
    }

    function onPoll(data) {
      console.log(' 🪲 onPoll data: ', data)
      setPoll(data.poll)
    }

    function onInfo(data) {
      console.log(' 🪲 onInfo data: ', data)
      if (data.anonymous !== undefined) {
        setAnon(data.anonymous)
      }
    }

    function onList(data) {
      console.log(' 🪲 onList data: ', data)
      setList(data)
    }
    
    socket.on('connect', onConnect);
    socket.on('create', onCreate);
    socket.on('join', onJoin);
    socket.on('poll', onPoll);
    socket.on('exist', onExist);
    socket.on('info', onInfo);
    socket.on('list', onList);
    socket.on('disconnect', onDisconnect);

    return () => {
      socket.off('connect', onConnect);
      socket.off('create', onCreate);
      socket.off('join', onJoin);
      socket.off('poll', onPoll);
      socket.off('exist', onExist);
      socket.off('info', onInfo);
      socket.off('list', onList);
      socket.off('disconnect', onDisconnect);
    };
  }, []);

  return (
    <div className="min-h-screen place-content-center">
      <Header />
      <div className="mx-4">
        <div className="max-w-md mt-8 mx-auto mr-2rounded-xl overflow-hidden md:max-w-2xl bg-black bg-opacity-50 shadow-lg py-6 px-6">
          <Routes>
            <Route path="/" element={<Home list={list} socket={socket} />} />
            <Route path="/create" element={<Create socket={socket} io={io} />} />
            <Route path="/poll/:id" element={<Poll socket={socket} io={io} user={user} poll={poll} exist={exist} anon={anon}/>} />
            <Route path="/poll/:id/join" element={<Join socket={socket} />} />
          </Routes>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default App;
 

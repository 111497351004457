import { useState, useEffect } from "react";
import { useNavigate, useLocation, Link} from "react-router-dom";

import { Title } from "../ui/title";
import { Button } from "../ui/button";
import { IconPlus } from "../ui/icons";

const Home = (props) => {
  const { list, socket } = props;
  const storage = localStorage
  const navigate = useNavigate();
  const location = useLocation();
  const FRONT_URL="https://poll.vaidis.eu"
  console.log("HOME storage:", storage)
  console.log("HOME location:", location.pathname)
  

  useEffect(() => {
    if (Object.keys(storage).length > 0) {
       socket.emit('list', storage);
    }
  },[])

  if (list && Object.keys(list).length > 0) {
    console.log("list:", list);
    return (
      <div>
        <div className="mb-8">
          <Title variant={1} label="Your polls:" />
        </div>
        <ul className="text-white">
        {
          list && list.map((item, index) => {
            return (
              <li className="my-6" key={index}>
                <Link to={`/poll/${item.id}`}>
                  <div className="flex flex-col shadow-lg">
                    <div className="bg-white text-black px-4 py-2 rounded-tl rounded-tr"><h3>{item.title}</h3></div>
                    <div className="bg-black text-white px-4 py-1 rounded-bl rounded-br text-xs flex flex-row justify-between">
                      <span className="text-gray-500">{item.users} votes</span>
                      <span>{item.anonymous ? <span>anonymous</span> : <span>{item.user}</span>}</span>
                    </div>
                  </div>
                </Link>              
              </li>
            )
          })
        }
      </ul>
      </div>
    )
  }
  else {
    return (
      <div className="flex flex-col">
        <Title variant={1} label="Lets make a poll really fast!" />
        <div className="text-white my-8">
          <p><span className="mr-4">&#9734;</span>$0 cost.</p>
          <p><span className="mr-4">&#9734;</span>No sign up. No personal data.</p>
          <p><span className="mr-4">&#9734;</span>Anonymous voting available!</p>
          <br/>
          <p>Just 3 quick steps:</p>
          <ul className="list-decimal my-8 pl-6">
            <li className="pl-2"><strong>Create</strong> the poll only with title and questions.</li>
            <li className="pl-2"><strong>Join</strong> the poll with a nickname.</li>
            <li className="pl-2"><strong>Share</strong> the poll url to voters.</li>
          </ul>
          <p>Surprice the voters, its free!</p>
        </div>
        <div className="w-15">
          <Button
            className="p-25"
            label="Create new poll"
            icon={<IconPlus />}
            onClick={() => navigate('/create')}
            big={true}
          />
        </div>
      </div>
    )
  }
}

export default Home

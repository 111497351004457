import { useNavigate } from "react-router-dom";

import { IconSignal, IconPlus } from "../ui/icons";
import { Button } from "../ui/button";

const Header = () => {
    const navigate = useNavigate();
    return (
        <div className="flex flex-grow justify-between bg-black bg-opacity-40 p-2">
            <div className="flex flex-row cursor-pointer" onClick={() => navigate('/')}>
                <div className="mr-0">
                    <IconSignal />
                </div>
                <div className="text-2xl font-bold text-white mt-1">Poll</div>
            </div>
            <div className="m-1">
                <Button
                    label="Create new poll"
                    icon={<IconPlus />}
                    onClick={() => navigate('/create')}
                />
            </div>
        </div>
    )
}

export default Header

import * as React from "react";
import { useRef } from "react";

const cls = "mr-2";


export const Checkbox = React.forwardRef(
  (props, ref) => {
    const {
      id,
      value,
      placeholder,
      name,
      checked,
      getRef,
      label,
      onClick,
      onChange,
      onFocus,
      onBlur,
      onKeyUp,
      onKeyDown,
    } = props;

    const checkboxRef = useRef(null);

    return (
      <>
        <label
          htmlFor={id}
          className="flex items-center cursor-pointer relative mb-4"
        >
          <input
            type="checkbox"
            id={id}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            ref={checkboxRef}
            className="sr-only"
            checked={checked}
          />
          <div className="toggle-bg bg-black h-6 w-11 rounded-full"></div>
          <span className="ml-3 text-white text-sm font-medium">
            Hide names for anonymous voting
          </span>
        </label>
      </>
    );
  }
);

import { useState, useEffect, useRef, useContext } from "react";
import classNames from "classnames";

const Answers = (props) => {
  const { poll, user, socket, id, pid, token } = props;
  const [winner, setWinner] = useState(0);
  // const token = localStorage.getItem(id)

  const onVote = async (answer) => {
    const data = { user:user, answer:answer, pid:pid, token:token }
    console.log('VOTE data: ', data)
    socket.emit("vote", data);
  };

  useEffect(() => {
    var res = poll.users.reduce(function (obj, v) {
      obj[v.vote] = (obj[v.vote] || 0) + 1;
      return obj;
    }, {})
    const arr = Object.values(res);
    const max = Math.max(...arr)
    setWinner(max)
  }, [poll])

  const countVotes = (index) => {
    let count = 0
    poll.users.filter(function (item) {
      if (item.vote === index) {
        count = count + 1
      }
    })

    return (
      <div className={`w-2/12 py-2 text-white text-2xl text-center rounded-l-lg ${winner === count ? 'bg-lime-500' : 'bg-black  bg-opacity-40'} `}>
        {count}
      </div>
    )
  }

  const buttonCN = classNames(`w-10/12 text-center bg-white w`)

  return (
    <div>
      {poll.answers.map((answer, index) => (
        <div key={index} className="flex flex-col my-8">
          <div className="flex flex-row">
            {countVotes(index)}
            <button className="w-10/12 p-2 text-center text-black align-middle bg-white rounded-r-lg drop-shadow-lg" onClick={() => onVote(index)} >
              {answer}
            </button>
          </div>
          <div className="mt-2">
            {
              poll.users.map((user, i) => {
                if (user.name != props.user && poll.anonymous) {
                  return null
                }
                if (index === user.vote) {
                  return (
                    <div
                      key={i}
                      className={` text-white text-sm float-left py-1 px-2 mr-2 mb-2 rounded-md ${user.name === props.user ? 'bg-blue-500 bg-opacity-100' : 'bg-black bg-opacity-40'}`}
                    >
                      {user.name === props.user && <span className="mr-2">&#10026;</span>}
                      {poll.anonymous ? 'Me' : user.name}
                    </div>)
                }
              })
            }
          </div>
        </div>
      ))}
    </div>
  );
}

export default Answers

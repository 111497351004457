import { useNavigate } from "react-router-dom";

import { useEffect, useState, useMemo } from "react";

import { Button } from '../ui/button';
import { Title } from '../ui/title';
import { Input } from '../ui/input';
import { Checkbox } from '../ui/checkbox';

const Create = (props) => {
  const {socket, io } = props;
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);

  const [formData, setFormData] = useState({
    title: "",
    answers: ["", ""],
    anonymous: true
  });

  useEffect(() => {
    // todo: validate for spaces only answer
    if (
      formData.answers.indexOf("") === -1 &&
      formData.answers.length > 1 &&
      formData.title
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [formData]);

  const handleInput = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleAnswers = (e, i) => {
    const newArr = [...formData.answers];
    newArr[i] = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      answers: newArr,
    }));
  };

  const addAnswer = (e) => {
    e.preventDefault();
    const newArr = [...formData.answers];
    newArr.push("");
    setFormData((prevState) => ({
      ...prevState,
      answers: newArr,
    }));
  };

  const delAnswer = (index) => {
    const newArr = [...formData.answers];
    newArr.splice(index, 1);
    setFormData((prevState) => ({
      ...prevState,
      answers: newArr,
    }));
  }

  const handleAnonymous = () => {
    setFormData((prevState) => ({
      ...prevState,
      anonymous: !prevState.anonymous,
    }));
  }

  const handlePublish = () => {
    const poll = {
      // id: memoid,
      title: formData.title,
      answers: formData.answers,
      anonymous: formData.anonymous
    };
    console.log('CREATE handlePublish poll: ', poll)
    socket.emit("create", poll);
  };

  return (
    <div>
      <form>
        <div className="mb-8">
          <Title variant={1} label="Create Poll" />
        </div>

        <div className="">
          <Title variant={2} label="Question" />
          <Input
            name="title"
            getRef={(ref) => handleInput(ref.current)}
            placeholder="Title"
            value={formData.title}
            onChange={handleInput}
          />
        </div>

        <div className="mt-10">
          <Title variant={2} label="Answers" />
          {formData.answers.map((option, i) => (
            <div key={i} className="flex">
              <div className="w-11/12">
                <Input
                  id={option}
                  name={option}
                  getRef={(ref) => handleInput(ref.current)}
                  value={formData.answers[i]}
                  onChange={(e) => handleAnswers(e, i)}
                />
              </div>
              {i > 1 && <div className="w-1/12 flex">
                <button
                  type='button'
                  onClick={() => delAnswer(i)}
                  className="text-red-500 text-2xl leading-10 font-extrabold ml-4"
                >
                  &#10005;
                </button>
              </div>}
            </div>
          ))}
          <div className="text-right pr-16">
            <button
              disabled={formData.answers.length > 100 ? true : false}
              onClick={(e) => addAnswer(e)}
              className="text-green-400"
            >
              &#43; Add answer
            </button>
          </div>
        </div>

        <div className="mt-10 ml-4 w-full">
          <Checkbox
            id='anon'
            label="Hide voters names (Anonymous voting)"
            onChange={handleAnonymous}
            checked={formData.anonymous}
          />
        </div>
        <div className="mt-2">
          <Button
            disabled={disabled}
            label={disabled ? 'Fill the form to publish the poll' : 'Publish the poll'}
            onClick={handlePublish}
            big={true}
          />
        </div>
      </form>
    </div>
  );
}

export default Create

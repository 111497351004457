import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Title } from "../ui/title";
import { Button } from "../ui/button";
import { Input } from "../ui/input";

const Join = (props) => {
  const {socket} = props
  const [user, setUser] = useState('');
  const { id } = useParams();

  const handleInput = (e) => {
    setUser(e.target.value);
  };
 
  const submit = () => {
    const data = {
      pid:id,
      user:user,
      userData: {
        appVersion: window.navigator.appVersion,
        language:  window.navigator.language,
        userAgentData: window.navigator.userAgentData,
      }
    };
    socket.emit('join', data);
    console.log('JOIN emit.join.data: ', data)
  };

  return (
    <div>
      <div className="">
        <Title variant={2} label="Nickname:" />
        <Input
          name="title"
          getRef={(ref) => handleInput(ref.current)}
          placeholder="Choose a name to vote...."
          value={user}
          onChange={handleInput}
        />
      </div>
      <div className='mt-8'>
        <Button
            disabled={!user}
            label="Let's vote!"
            onClick={submit}
            big
          />
      </div>
    </div>
  );
}

export default Join

export const IconPoll = () => <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19 4H5C4.44771 4 4 4.44772 4 5V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V5C20 4.44771 19.5523 4 19 4ZM5 2C3.34315 2 2 3.34315 2 5V19C2 20.6569 3.34315 22 5 22H19C20.6569 22 22 20.6569 22 19V5C22 3.34315 20.6569 2 19 2H5Z"
        fill="currentColor"
    />
    <path d="M11 7H13V17H11V7Z" fill="currentColor" />
    <path d="M15 13H17V17H15V13Z" fill="currentColor" />
    <path d="M7 10H9V17H7V10Z" fill="currentColor" />
</svg>
export const IconUser2 = () => <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        d="M12 17C14.2091 17 16 15.2091 16 13H8C8 15.2091 9.79086 17 12 17Z"
        fill="currentColor"
    />
    <path
        d="M10 10C10 10.5523 9.55228 11 9 11C8.44772 11 8 10.5523 8 10C8 9.44772 8.44772 9 9 9C9.55228 9 10 9.44772 10 10Z"
        fill="currentColor"
    />
    <path
        d="M15 11C15.5523 11 16 10.5523 16 10C16 9.44772 15.5523 9 15 9C14.4477 9 14 9.44772 14 10C14 10.5523 14.4477 11 15 11Z"
        fill="currentColor"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
        fill="currentColor"
    />
</svg>

export const IconUser = () => <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        d="M10 12C10 12.5523 9.55228 13 9 13C8.44772 13 8 12.5523 8 12C8 11.4477 8.44772 11 9 11C9.55228 11 10 11.4477 10 12Z"
        fill="currentColor"
    />
    <path
        d="M15 13C15.5523 13 16 12.5523 16 12C16 11.4477 15.5523 11 15 11C14.4477 11 14 11.4477 14 12C14 12.5523 14.4477 13 15 13Z"
        fill="currentColor"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0244 2.00003L12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.74235 17.9425 2.43237 12.788 2.03059L12.7886 2.0282C12.5329 2.00891 12.278 1.99961 12.0244 2.00003ZM12 20C16.4183 20 20 16.4183 20 12C20 11.3014 19.9105 10.6237 19.7422 9.97775C16.1597 10.2313 12.7359 8.52461 10.7605 5.60246C9.31322 7.07886 7.2982 7.99666 5.06879 8.00253C4.38902 9.17866 4 10.5439 4 12C4 16.4183 7.58172 20 12 20ZM11.9785 4.00003L12.0236 4.00003L12 4L11.9785 4.00003Z"
        fill="currentColor"
    />
</svg>

export const IconCopy = () => <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path d="M13 7H7V5H13V7Z" fill="currentColor" />
    <path d="M13 11H7V9H13V11Z" fill="currentColor" />
    <path d="M7 15H13V13H7V15Z" fill="currentColor" />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3 19V1H17V5H21V23H7V19H3ZM15 17V3H5V17H15ZM17 7V19H9V21H19V7H17Z"
        fill="currentColor"
    />
</svg>


export const IconSignal = () => <svg
    width="30"
    height="30"
    viewBox="0 0 16 20"
    fill="white"
>
    <path
        d="M15 7C15 6.44772 15.4477 6 16 6C16.5523 6 17 6.44772 17 7V17C17 17.5523 16.5523 18 16 18C15.4477 18 15 17.5523 15 17V7Z"
        fill="white"
    />
    <path
        d="M7 15C7 14.4477 7.44772 14 8 14C8.55228 14 9 14.4477 9 15V17C9 17.5523 8.55228 18 8 18C7.44772 18 7 17.5523 7 17V15Z"
        fill="white"
    />
    <path
        d="M12 10C11.4477 10 11 10.4477 11 11V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V11C13 10.4477 12.5523 10 12 10Z"
        fill="white"
    />
</svg>

export const IconNew = () => <svg
    viewBox="0 0 24 24"
    height="48"
    width="48"
    fill="currentColor"
>
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M6.455 19L2 22.5V4a1 1 0 011-1h18a1 1 0 011 1v14a1 1 0 01-1 1H6.455zM11 10H8v2h3v3h2v-3h3v-2h-3V7h-2v3z" />
</svg>

export const IconPlus = () => <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    height="1.2em"
    width="1.2em"
>
    <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4z" />
    <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z" />
</svg>


export const IconLock = () => <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    height="1.2em"
    width="1.2em"
>
    <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 20">
    <path d="M14 7h-1.5V4.5a4.5 4.5 0 1 0-9 0V7H2a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2Zm-5 8a1 1 0 1 1-2 0v-3a1 1 0 1 1 2 0v3Zm1.5-8h-5V4.5a2.5 2.5 0 1 1 5 0V7Z"/>
  </svg>
</svg>

export const IconUnlock = () => <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    height="1.2em"
    width="1.2em"
>
    <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M15.077.019a4.658 4.658 0 0 0-4.083 4.714V7H2a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-1.006V4.68a2.624 2.624 0 0 1 2.271-2.67 2.5 2.5 0 0 1 2.729 2.49V8a1 1 0 0 0 2 0V4.5A4.505 4.505 0 0 0 15.077.019ZM9 15.167a1 1 0 1 1-2 0v-3a1 1 0 1 1 2 0v3Z"/>
  </svg>
</svg>

export const IconTimer = () => <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    height="1.2em"
    width="1.2em"
>
    <g>
    	<path d="M460.377,61.143c-9.835-5.704-22.4-2.329-28.104,7.545l-20.554,35.567l71.221,41.109l20.555-35.567c5.661-9.874,2.29-22.4-7.545-28.104L460.377,61.143z"/>
    	<path d="M149.189,61.143l-35.568,20.555c-9.835,5.704-13.206,18.226-7.549,28.104l20.554,35.568l71.222-41.109l-20.555-35.567C171.589,58.814,159.024,55.443,149.189,61.143z"/>
    	<path d="M306,92.56c13,0,25.699,1.272,38.25,3.065V62.357l26.297-0.201V19.125C370.547,8.554,361.993,0,351.422,0h-90.643c-10.571,0-19.125,8.554-19.125,19.125v43.031l26.096,0.201v33.268C280.301,93.832,293,92.56,306,92.56z"/>
    	<path d="M306,203.5c-88.295,0-159.875,71.575-159.875,159.875c0,88.3,71.575,159.875,159.875,159.875c88.3,0,159.875-71.58,159.875-159.875C465.875,275.08,394.295,203.5,306,203.5z M328.988,392.67c-9.252,8.434-27.736,3.228-41.286-11.628c-13.55-14.86-17.041-33.746-7.789-42.181c9.251-8.434,92.005-54.057,117.303-57.695C391.264,306.02,338.24,384.235,328.988,392.67z"/>
    	<path d="M306,114.75c-137.312,0-248.625,111.312-248.625,248.625S168.688,612,306,612s248.625-111.312,248.625-248.625S443.312,114.75,306,114.75z M306,565.105c-111.413,0-201.73-90.317-201.73-201.73c0-111.413,90.318-201.735,201.73-201.735c111.413,0,201.735,90.323,201.735,201.735C507.735,474.788,417.413,565.105,306,565.105z"/>
    </g>
</svg>


export const IconGroup = () => <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    height="1.2em"
    width="1.2em"
>
    <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 19">
    <path d="M14.5 0A3.987 3.987 0 0 0 11 2.1a4.977 4.977 0 0 1 3.9 5.858A3.989 3.989 0 0 0 14.5 0ZM9 13h2a4 4 0 0 1 4 4v2H5v-2a4 4 0 0 1 4-4Z"/>
    <path d="M5 19h10v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2ZM5 7a5.008 5.008 0 0 1 4-4.9 3.988 3.988 0 1 0-3.9 5.859A4.974 4.974 0 0 1 5 7Zm5 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm5-1h-.424a5.016 5.016 0 0 1-1.942 2.232A6.007 6.007 0 0 1 17 17h2a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5ZM5.424 9H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h2a6.007 6.007 0 0 1 4.366-5.768A5.016 5.016 0 0 1 5.424 9Z"/>
  </svg>
</svg>



export const IconUser3 = () => <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    height="1.2em"
    width="1.2em"
>
    <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 14 18">
    <path d="M7 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Zm2 1H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z"/>
  </svg>
</svg>


export const IconClock = () => <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    height="1.2em"
    width="1.2em"
>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM11.8284 6.75736C12.3807 6.75736 12.8284 7.20507 12.8284 7.75736V12.7245L16.3553 14.0653C16.8716 14.2615 17.131 14.8391 16.9347 15.3553C16.7385 15.8716 16.1609 16.131 15.6447 15.9347L11.4731 14.349C11.085 14.2014 10.8284 13.8294 10.8284 13.4142V7.75736C10.8284 7.20507 11.2761 6.75736 11.8284 6.75736Z" fill="#0F1729"/>
</svg>

export const IconAnonymous = () => <svg
    viewBox="0 0 24 24"
    height="48"
    width="48"
    fill="currentColor"
>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.6138 8.54479L4.1875 10.25H2C1.58579 10.25 1.25 10.5858 1.25 11C1.25 11.4142 1.58579 11.75 2 11.75H22C22.4142 11.75 22.75 11.4142 22.75 11C22.75 10.5858 22.4142 10.25 22 10.25H19.8125L19.3862 8.54479C18.8405 6.36211 18.5677 5.27077 17.7539 4.63538C16.9401 4 15.8152 4 13.5653 4H10.4347C8.1848 4 7.05988 4 6.24609 4.63538C5.43231 5.27077 5.15947 6.36211 4.6138 8.54479ZM6.5 21C8.12316 21 9.48826 19.8951 9.88417 18.3963L10.9938 17.8415C11.6272 17.5248 12.3728 17.5248 13.0062 17.8415L14.1158 18.3963C14.5117 19.8951 15.8768 21 17.5 21C19.433 21 21 19.433 21 17.5C21 15.567 19.433 14 17.5 14C15.8399 14 14.4498 15.1558 14.0903 16.7065L13.6771 16.4999C12.6213 15.972 11.3787 15.972 10.3229 16.4999L9.90967 16.7065C9.55023 15.1558 8.16009 14 6.5 14C4.567 14 3 15.567 3 17.5C3 19.433 4.567 21 6.5 21Z" fill="#1C274C"/>
</svg>





import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  FacebookShareButton,
  FacebookIcon,
  ViberShareButton,
  ViberIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon } from "react-share";

import { Title } from "../ui/title";
import Answers from './Answers'

function Poll(props) {
  const { user, anon, poll, exist, socket } = props
  const { id } = useParams();
  const navigate = useNavigate();
  const token = localStorage.getItem(id)
  const FRONT_URL = `https://${window.location.hostname}`
  const shareUrl = `${FRONT_URL}/poll/${id}`

  const [copied, setCopied] = useState(false);

  const randomUser = (length) => {
    let result = "";
    const characters = "abcdefghijklmnopqrstuvwxyz123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  useEffect(() => {
    if (!token) {
      const data = {
        pid:id,
        userData: {
          appVersion: window.navigator.appVersion,
          language:  window.navigator.language,
          languages:  window.navigator.languages,
          userAgentData: window.navigator.userAgentData,
          screen: window.screen
        }
      }
      console.log(' 🎱 id:', id)
      console.log(' 🎱 token:', Boolean(token))
      socket.emit('join', data);
    }
    if (token) {
      const data = {pid:id, token: token};
      console.log(' 🎱 id:', id)
      console.log(' 🎱 token:', Boolean(token))
      socket.emit('join', data);
    }
  },[id, token])

  useEffect(() => {
    if (token) {
      console.log(' 🎱 id:', id)
      console.log(' 🎱 token:', Boolean(token))
      console.log(' emit.poll.token:', token)
      socket.emit('poll', token);
    }
  },[token])

  const onCopy = () => {
    console.log('onCopy')
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }

  return (
    <div className="poll">
    {poll &&
      <div className="mb-8">
      <Title variant={1} label={poll.title} />
      <p className="text-white mt-6 text-sm">Hello <strong>{poll.anonymous ? 'Anonymous' : user}</strong>, please choose the answer you like</p>
      <Answers poll={poll} pid={id} user={user} socket={socket} token={token}/>
      </div>
    }
    {/* SHARE */}
    <div className="mt-2 text-white">

    <p className="text-sm mb-3">Share the poll URL to the voters</p>
    <div className="flex p-4 bg-black bg-opacity-20 rounded-xl justify-between">
    <div className="text-xs">
    {FRONT_URL}/poll/{id}
    </div>
    <div className="">
    <CopyToClipboard onCopy={onCopy} text={`${FRONT_URL}/poll/${id}`}>
    <i className="flex mr-2 gg-copy"></i>
    </CopyToClipboard>
    </div>
    </div>
    <div className={`transition-opacity duration-600 flex justify-end text-sm mr-2 ${copied ? 'opacity-100' : 'opacity-0'}`}>
    Copied
    </div>

    <div className="flex flex-row justify-center">

    <div className="mr-4">
    <ViberShareButton url={shareUrl}>
    <ViberIcon size={32} round />
    </ViberShareButton>
    </div>

    <div className="mr-4">
    <FacebookShareButton url={shareUrl}>
    <FacebookIcon size={32} round />
    </FacebookShareButton>
    </div>

    <div className="mr-4">
    <LinkedinShareButton url={shareUrl}>
    <LinkedinIcon size={32} round />
    </LinkedinShareButton>
    </div>

    <div className="mr-4">
    <TwitterShareButton url={shareUrl}>
    <TwitterIcon size={32} round />
    </TwitterShareButton>
    </div>

    <div className="mr-4">
    <TelegramShareButton url={shareUrl}>
    <TelegramIcon size={32} round />
    </TelegramShareButton>
    </div>

    <div className="mr-4">
    <WhatsappShareButton url={shareUrl}>
    <WhatsappIcon size={32} round />
    </WhatsappShareButton>
    </div>

    </div>
    </div>
    </div>
  );
}

export default Poll;

import { io } from 'socket.io-client';

const options = {
	rejectUnauthorized: false,
};

const URL = 'https://pollback.vaidis.eu:443';
//const URL = 'wss://pollback.vaidis.eu';

export const socket = io(URL, options);

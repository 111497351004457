import * as React from "react";
import { useRef } from "react";

const cls =
  "peer block min-h-[auto] w-full rounded border-1 px-3 py-[0.32rem] leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-1 bg-white bg-opacity-5 text-white";


export const Input = React.forwardRef(
  (props, ref) => {
    const {
      id,
      value,
      placeholder,
      name,
      getRef,
      onClick,
      onChange,
      onFocus,
      onBlur,
      onKeyUp,
      onKeyDown,
    } = props;

    const inputRef = useRef(null);

    return (
      <>
        <div className="relative mb-2 mt-2" data-te-input-wrapper-init>
          <input
            type="text"
            id={id}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            ref={inputRef}
            className={cls}
          />
        </div>
      </>
    );
  }
);

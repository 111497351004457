import classNames from "classnames";

export const Button = ({
  label,
  onClick,
  disabled,
  icon,
  big
}) => {
  const clsCommon = 'flex text-white text-center w-full justify-center rounded-lg';
  const clsEnabled = 'bg-gradient-to-r from-green-500 to-blue-500 hover:from-green-600 hover:to-blue-600 focus:outline-none shadow-lg rounded-lg mx-auto shadow-gray-800';
  const clsDisabled = 'text-slate-500 bg-black';
  const clsBig = `p-5`
  const clsSmall = `py-1 px-3`

  const cls = classNames(
    clsCommon,
    {[clsEnabled]: !disabled},
    {[clsDisabled]: disabled},
    {[clsBig]: big},
    {[clsSmall]: !big}
  )

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type="button"
      className={cls}
    >
      <div className="flex flex-row align-middle items-center">
        <div className="mr-2">{icon}</div>
        <div className="">{label}</div>
      </div>
    </button>
  );
};
